'use strict';

var cov_2q1tgnb761 = function () {
  var path = '/workdir/src/app/testimonials/testimonials.js',
      hash = 'bbd156c95055ecbd28535fcb646c5a3f0824626d',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/testimonials/testimonials.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 14,
          column: 45
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 15,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 8,
            column: 11
          },
          end: {
            line: 8,
            column: 21
          }
        },
        loc: {
          start: {
            line: 8,
            column: 24
          },
          end: {
            line: 10,
            column: 3
          }
        },
        line: 8
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2q1tgnb761.s[0]++;
(function () {
  cov_2q1tgnb761.f[0]++;

  var testimonials = (cov_2q1tgnb761.s[1]++, {
    templateUrl: 'app/testimonials/testimonials.html',
    controller: controller
  });

  function controller() {
    cov_2q1tgnb761.f[1]++;
  }

  cov_2q1tgnb761.s[2]++;
  angular.module('app').component('testimonials', testimonials);
})();