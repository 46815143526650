'use strict';

var cov_1pgsv2nhfe = function () {
  var path = '/workdir/src/app/contact/contact.js',
      hash = 'dc610d9358abaa23ab37a95c8e92f028f89be9c9',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/contact/contact.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 80,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 18
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 53
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 122
        }
      },
      '5': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '6': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 70,
          column: 6
        }
      },
      '7': {
        start: {
          line: 52,
          column: 23
        },
        end: {
          line: 52,
          column: 90
        }
      },
      '8': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 57,
          column: 7
        }
      },
      '9': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 15
        }
      },
      '10': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 69,
          column: 11
        }
      },
      '11': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 28
        }
      },
      '12': {
        start: {
          line: 62,
          column: 10
        },
        end: {
          line: 62,
          column: 28
        }
      },
      '13': {
        start: {
          line: 63,
          column: 10
        },
        end: {
          line: 63,
          column: 30
        }
      },
      '14': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 66,
          column: 19
        }
      },
      '15': {
        start: {
          line: 65,
          column: 12
        },
        end: {
          line: 65,
          column: 33
        }
      },
      '16': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 26
        }
      },
      '17': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 74,
          column: 6
        }
      },
      '18': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 24
        }
      },
      '19': {
        start: {
          line: 77,
          column: 2
        },
        end: {
          line: 79,
          column: 35
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 80,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 45
          },
          end: {
            line: 75,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 51,
            column: 16
          },
          end: {
            line: 51,
            column: 17
          }
        },
        loc: {
          start: {
            line: 51,
            column: 25
          },
          end: {
            line: 70,
            column: 5
          }
        },
        line: 51
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 60,
            column: 14
          },
          end: {
            line: 60,
            column: 15
          }
        },
        loc: {
          start: {
            line: 60,
            column: 25
          },
          end: {
            line: 67,
            column: 9
          }
        },
        line: 60
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 64,
            column: 19
          },
          end: {
            line: 64,
            column: 20
          }
        },
        loc: {
          start: {
            line: 64,
            column: 25
          },
          end: {
            line: 66,
            column: 11
          }
        },
        line: 64
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 67,
            column: 11
          },
          end: {
            line: 67,
            column: 12
          }
        },
        loc: {
          start: {
            line: 67,
            column: 20
          },
          end: {
            line: 69,
            column: 9
          }
        },
        line: 67
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 72,
            column: 19
          },
          end: {
            line: 72,
            column: 20
          }
        },
        loc: {
          start: {
            line: 72,
            column: 25
          },
          end: {
            line: 74,
            column: 5
          }
        },
        line: 72
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }, {
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }],
        line: 55
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1pgsv2nhfe.s[0]++;
(function () {
  cov_1pgsv2nhfe.f[0]++;

  var contact = (cov_1pgsv2nhfe.s[1]++, {
    templateUrl: 'app/contact/contact.html',
    controller: controller
  });

  cov_1pgsv2nhfe.s[2]++;
  controller.$inject = ['$http', '$log', '$timeout'];

  function controller($http, $log, $timeout) {
    cov_1pgsv2nhfe.f[1]++;

    var self = (cov_1pgsv2nhfe.s[3]++, this);

    cov_1pgsv2nhfe.s[4]++;
    mapboxgl.accessToken = 'pk.eyJ1Ijoicnlhbmx1dG9uIiwiYSI6ImNqMTVlbDc3ejAxMTIycXFxZnJpbGk2eWkifQ.99fgYtx_GBCopZ83PRwWCg';
    cov_1pgsv2nhfe.s[5]++;
    self.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/light-v9', // stylesheet location
      center: [-96.973349, 33.044835], // starting position
      zoom: 13 // starting zoom
    });
    // map.addSource('markers', {
    //   type: 'geojson',
    //   data: {
    //     type: 'FeatureCollection',
    //     features: [{
    //       type: 'Feature',
    //       geometry: {
    //         type: 'Point',
    //         coordinates: [-96.973349, 33.044835]
    //       },
    //       properties: {
    //         title: 'QMS',
    //         'marker-symbol': 'default_marker'
    //       }
    //     }]
    //   }
    // });

    // map.addLayer({
    //   id: 'markers',
    //   type: 'symbol',
    //   source: 'markers',
    //   layout: {
    //     'icon-image': '{marker-symbol}-15',
    //     'text-field': '{title}',
    //     'text-font': ['Open Sans', 'Arial Unicode MS Bold'],
    //     'text-offset': [0, 0.6],
    //     'text-anchor': 'top'
    //   }
    // });

    cov_1pgsv2nhfe.s[6]++;
    self.send = function (valid) {
      cov_1pgsv2nhfe.f[2]++;

      var endpoint = (cov_1pgsv2nhfe.s[7]++, 'https://2lkd297to6.execute-api.us-west-2.amazonaws.com/v1/contact');
      // const options = {};

      cov_1pgsv2nhfe.s[8]++;
      if (!valid) {
        cov_1pgsv2nhfe.b[0][0]++;
        cov_1pgsv2nhfe.s[9]++;

        return;
      } else {
        cov_1pgsv2nhfe.b[0][1]++;
      }

      cov_1pgsv2nhfe.s[10]++;
      $http.post(endpoint, self.message).then(function (success) {
        cov_1pgsv2nhfe.f[3]++;
        cov_1pgsv2nhfe.s[11]++;

        $log.log(success);
        cov_1pgsv2nhfe.s[12]++;
        self.message = {};
        cov_1pgsv2nhfe.s[13]++;
        self.success = true;
        cov_1pgsv2nhfe.s[14]++;
        $timeout(function () {
          cov_1pgsv2nhfe.f[4]++;
          cov_1pgsv2nhfe.s[15]++;

          self.success = false;
        }, 3000);
      }, function (error) {
        cov_1pgsv2nhfe.f[5]++;
        cov_1pgsv2nhfe.s[16]++;

        $log.log(error);
      });
    };

    cov_1pgsv2nhfe.s[17]++;
    self.$onInit = function () {
      cov_1pgsv2nhfe.f[6]++;
      cov_1pgsv2nhfe.s[18]++;

      self.message = {};
    };
  }

  cov_1pgsv2nhfe.s[19]++;
  angular.module('app').component('contact', contact);
})();