'use strict';

var cov_pima5tw2f = function () {
  var path = '/workdir/src/app/app.js',
      hash = 'f385708dc3339a0d1648231bb5df78697db489b5',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/app/app.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 14
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 45
        }
      },
      '3': {
        start: {
          line: 11,
          column: 17
        },
        end: {
          line: 11,
          column: 21
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 16,
          column: 9
        }
      },
      '5': {
        start: {
          line: 14,
          column: 8
        },
        end: {
          line: 14,
          column: 45
        }
      },
      '6': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 29
        }
      },
      '7': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 22,
          column: 6
        }
      },
      '8': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 21,
          column: 7
        }
      },
      '9': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 47
        }
      },
      '10': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 26,
          column: 27
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 1
          },
          end: {
            line: 2,
            column: 2
          }
        },
        loc: {
          start: {
            line: 2,
            column: 13
          },
          end: {
            line: 27,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'controller',
        decl: {
          start: {
            line: 10,
            column: 11
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 39
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 22
          },
          end: {
            line: 13,
            column: 23
          }
        },
        loc: {
          start: {
            line: 13,
            column: 28
          },
          end: {
            line: 16,
            column: 7
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 21
          },
          end: {
            line: 18,
            column: 22
          }
        },
        loc: {
          start: {
            line: 18,
            column: 27
          },
          end: {
            line: 22,
            column: 5
          }
        },
        line: 18
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }, {
          start: {
            line: 19,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }],
        line: 19
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_pima5tw2f.s[0]++;
(function () {
  cov_pima5tw2f.f[0]++;

  var app = (cov_pima5tw2f.s[1]++, {
    templateUrl: 'app/app.html',
    controller: controller
  });

  cov_pima5tw2f.s[2]++;
  controller.$inject = ['$window', '$scope'];

  function controller($window, $scope) {
    var _this = this;

    cov_pima5tw2f.f[1]++;

    var self = (cov_pima5tw2f.s[3]++, this);
    cov_pima5tw2f.s[4]++;
    angular.element($window).bind('scroll', function () {
      cov_pima5tw2f.f[2]++;
      cov_pima5tw2f.s[5]++;

      _this.scrollTop = $window.pageYOffset;
      cov_pima5tw2f.s[6]++;
      $scope.$applyAsync();
    });

    cov_pima5tw2f.s[7]++;
    self.toggleNav = function () {
      cov_pima5tw2f.f[3]++;
      cov_pima5tw2f.s[8]++;

      if (!self.navCollapsed) {
        cov_pima5tw2f.b[0][0]++;
        cov_pima5tw2f.s[9]++;

        self.navCollapsed = !self.navCollapsed;
      } else {
        cov_pima5tw2f.b[0][1]++;
      }
    };
  }
  cov_pima5tw2f.s[10]++;
  angular.module('app').component('app', app);
})();