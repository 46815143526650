'use strict';

var cov_1u71lwtfty = function () {
  var path = '/workdir/src/index.js',
      hash = 'a666f73abb7ae7288743ae948edf0d134cce7eaa',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 9,
          column: 5
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1u71lwtfty.s[0]++;
angular.module('app', ['ui.router', 'ng-backstretch', 'ui.bootstrap', 'ngAnimate', 'duScroll', 'ui-leaflet']);