'use strict';

var cov_1lt91yntzh = function () {
  var path = '/workdir/src/routes.js',
      hash = '4559c8b5220bf8bbd20b5dd0c55e554d4bc7c929',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/workdir/src/routes.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 107
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 52
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 36
        }
      },
      '3': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 14,
          column: 7
        }
      },
      '4': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: 'routesConfig',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 21
          }
        },
        loc: {
          start: {
            line: 6,
            column: 95
          },
          end: {
            line: 17,
            column: 1
          }
        },
        line: 6
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1lt91yntzh.s[0]++;
angular.module('app').config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$compileProvider', routesConfig]);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider, $compileProvider) {
  cov_1lt91yntzh.f[0]++;
  cov_1lt91yntzh.s[1]++;

  $locationProvider.html5Mode(true).hashPrefix('!');
  cov_1lt91yntzh.s[2]++;
  $urlRouterProvider.otherwise('/');

  cov_1lt91yntzh.s[3]++;
  $stateProvider.state('app', {
    url: '/',
    component: 'app'
  });

  cov_1lt91yntzh.s[4]++;
  $compileProvider.debugInfoEnabled(false);
}